import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';
import { colors } from 'styles/theme';
import { space, color, typography } from 'styled-system';

import { Layout, SEO, Grid, Box, Text } from 'components';

const TextContainer = styled(Text.withComponent('div'))`
  p {
    margin-bottom: 1em;
  },
  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
  },
  li {
    list-style-type: disc;
  }
`;

const Headline = Text.withComponent('h3');

const ListItem = styled(Text.withComponent('li'))`
  ${space}
  position: relative;
  padding-left: 1.5em;

  &::before {
    content: '•';
    font-size: 1em;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ApplyButton = styled(Text.withComponent('a'))`
  padding: 16px 24px;
  border-radius: 24px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    box-shadow: inset 0 0 0 1px ${colors.gray};
    background: transparent;
  }
`;

const SmallText = styled.p`
  ${color}
  ${space}
  ${typography};
`;

const InfoCard = styled(Box)`
  border-radius: 2rem;
  border: 1px solid ${colors.gray};
  cursor: pointer;

  &:nth-of-type(1) {
    z-index: 3;
  }

  &:nth-of-type(2) {
    z-index: 2;
  }

  &:nth-of-type(3) {
    z-index: 1;
  }

  &:hover {
    z-index: 4;
    color: ${colors.black};
  }
`;

InfoCard.defaultProps = {
  p: 3,
  pt: 5,
  bg: 'white',
  color: 'blackDimmed',
  gridRow: ['auto', 1],
  mb: [3, 0]
};

const JobItem = props => {
  const job = props.data.prismic.allJob_items.edges.slice(0, 1).pop();
  if (!job) return null;

  const metaData = {
    title: job.node.title
      ? `B-Reel is looking for a ${RichText.asText(job.node.title)}!`
      : 'B-Reel is Hiring!',
    description: job.node.description
      ? RichText.asText( job.node.description )
      : null
  };

  return (
    <Layout>
      <SEO {...metaData} />

      <Grid>
        <Box gridColumn={['2 / -2', '3 / -3']} pt={7}>
          <Text size="2x" textAlign="center">
            {RichText.asText(job.node.title)}
          </Text>

          {job.node.office && job.node.date_published ? (
            <Text color="blackDimmed" pt=".5em" textAlign="center">
              Published {moment(job.node.date_published).fromNow()} ·{' '}
              {RichText.asText(job.node.office.city)} office
            </Text>
          ) : null}

          <TextContainer py={5}>
            {RichText.render(job.node.description)}
          </TextContainer>

          <Box textAlign="center" mb={7}>
            <ApplyButton
              bg="gray"
              color="black"
              href={job.node.breezy_url.url}
              target="_blank"
            >
              Apply now
            </ApplyButton>

            {job.node.expiry_date ? (
              <SmallText fontSize={14} color="blackDimmed" mt="1em">
                Deadline—{moment(job.node.expiry_date).format('MMMM Do, YYYY')}
              </SmallText>
            ) : null}
          </Box>
        </Box>
      </Grid>

      <Grid mb={7}>
        <InfoCard gridColumn={['2 / -2', '2 / span 5']}>
          <Text>
            We believe in the magic of being interested. In the world; its
            culture, craft, technology, and above all, its people. Interested in
            problems great and small, and their unexpected solutions.
          </Text>
          <Text mt={5}>one</Text>
        </InfoCard>

        <InfoCard gridColumn={['2 / -2', '5 / span 5']}>
          <Text>
            In seeing every project as an opportunity to challenge how things
            have always been done. To create new ways to engage people, use
            technology, and work
          </Text>
          <Text mt={5}>two</Text>
        </InfoCard>

        <InfoCard gridColumn={['2 / -2', '7 / span 5']}>
          <Text>
            In being stubbornly independent and deliberately lean. 5 nimble
            offices with one shared culture of curiosity. Designed for change
            and forward motion.
          </Text>
          <Text mt={5}>three</Text>
        </InfoCard>
      </Grid>
    </Layout>
  );
};

export default JobItem;

export const query = graphql`
  query JobItemQuery($uid: String) {
    prismic {
      allJob_items(uid: $uid) {
        edges {
          node {
            title
            expiry_date
            description
            date_published
            breezy_url {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            office {
              ... on PRISMIC_Office {
                _meta {
                  uid
                }
                city
              }
            }
          }
        }
      }
    }
  }
`;
